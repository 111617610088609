<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CreditNotesTable
          :items="credit_notes"
          hover
          striped
          border
          small
          fixed
          caption="Notas de Crédito"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CreditNotesTable from '../components/credit-notes/CreditNotesTable.vue'
import ws from '../services/credit-notes';
import store from '../store'

export default {
  name: 'CreditNotes',
  components: { CreditNotesTable },
  data: function () {
		return {
            credit_notes: []
        }
  },
  mounted: async function() {
    this.loading();
      let response = await ws.get('active'); 

      if(response.type == "success"){
        this.credit_notes = response.data;
      }

      this.loaded();
  },
  methods: {
    async refreshItems (tab,filters = {}){
      this.loading();
      let response = await ws.get(tab,filters); 
      
      if(response.type == "success"){
        this.credit_notes = response.data;
      }

      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
