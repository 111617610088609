import axios from 'axios'
import store from '../store'

export default {
    get: (tab,filters) => axios.post('credit-notes/list/'+tab,filters),
    getUUIDs: (rfc, uuids) => axios.post('credit-notes/uuids/'+rfc, uuids),
    getUUIDsGreaterDate: (rfc, date) => axios.post('credit-notes/uuids/greater-date/'+rfc, {"date": date}),
    getTotals: (filters) => axios.post('credit-notes/list/count',filters),
    show: (id) => axios.get('credit-notes/'+id+'/show'),
    downloadXML: (id) => axios.get('credit-notes/'+id+'/download-xml').then(function(response){
        if(response.type == "success"){
            window.open(axios.defaults.baseURL+"/s3?file="+response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadPDF: (id) => axios.get('credit-notes/'+id+'/download-pdf').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadExcel: (filters) => axios.post('credit-notes/list/download-excel',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadZip: (filters) => axios.post('credit-notes/list/download-zip',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),    
    downloadCancelationPDF: (id) => axios.get('credit-notes/'+id+'/cancel/download-pdf').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    store: (data) => axios.post('credit-notes/store', data),
    update: (data) => axios.post('credit-notes/update', data),
    delete: (id) => axios.post('credit-notes/delete', id),
    cancel: (id, data) => axios.post('credit-notes/'+id+'/cancel', data),
    send: (id, data) => axios.post('credit-notes/'+id+'/send/mail', data),
    preview: (data) => axios.post('credit-notes/preview', data).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');     
        }

        return response;
    }),
}